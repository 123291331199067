import React from 'react'

function DescriptionBox(props) {
  return (
    <article
      className={
        'description-box ' +
        (props.additionalClass ? props.additionalClass : '')
      }
    >
      {props.icon ? <span className="description-box__icon">{props.icon}</span> : null} 
      <h2 className={`description-box__heading ${props.smallHeading ? 'text--4' : 'text--3'}`}>{props.heading}</h2>
      <p className="description-box__paragraph text--5">{props.paragraph1}</p>
      {props.paragraph2 ? (
        <p className="description-box__paragraph text--5">{props.paragraph2}</p>
      ) : null}
    </article>
  )
}

export default DescriptionBox
