import React from 'react'

export const ImpressionIcon = () => {
    return (
        <svg width="73" height="74" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <g id="net" fill="currentColor">
                    <g id="Group_100" transform="translate(54.822 62.164)">
                      <g id="Group_99">
                        <path
                          d="M1.427 0a1.426 1.426 0 1 0 1.009.416A1.425 1.425 0 0 0 1.425 0h.002z"
                          id="Path_12"
                        />
                      </g>
                    </g>
                    <g id="Group_102" transform="translate(0 20.818)">
                      <g id="Group_101">
                        <path
                          d="M71.574 41.346h-1.711V6.772A6.779 6.779 0 0 0 63.091 0h-5.347a1.426 1.426 0 1 0 0 2.852h5.347a3.925 3.925 0 0 1 3.92 3.92v34.574h-3.92a1.426 1.426 0 1 0 0 2.852h7.058v1.177a3.961 3.961 0 0 1-3.956 3.956H6.808a3.961 3.961 0 0 1-3.956-3.956v-1.177h47.013a1.426 1.426 0 1 0 0-2.852H5.988V6.772a3.925 3.925 0 0 1 3.92-3.92h6.132a1.426 1.426 0 1 0 0-2.852H9.908a6.779 6.779 0 0 0-6.772 6.772v34.574h-1.71c-.787 0-1.425.638-1.426 1.425v2.6a6.815 6.815 0 0 0 6.808 6.815h59.384A6.815 6.815 0 0 0 73 45.374v-2.6a1.426 1.426 0 0 0-1.426-1.428z"
                          id="Path_13"
                        />
                      </g>
                    </g>
                    <g id="Group_104" transform="translate(30.961 66.156)">
                      <g id="Group_103">
                        <path
                          d="M8.723 0h-6.3a1.426 1.426 0 0 0 0 2.852h6.3a1.426 1.426 0 0 0 0-2.852z"
                          id="Path_14"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_106"
                      transform="translate(21.814 29.947)"
                      fillRule="nonzero"
                    >
                      <g id="Group_105">
                        <path
                          d="M14.686 0C6.576 0 .002 6.575.002 14.686c0 8.11 6.575 14.684 14.685 14.684s14.685-6.575 14.685-14.685C29.364 6.578 22.793.008 14.686 0zM2.852 14.685c0-.912.107-1.821.317-2.709h3.168v5.418H3.169a11.817 11.817 0 0 1-.317-2.709zm1.392 5.561h2.093v.772c0 .804.117 1.604.346 2.375a11.908 11.908 0 0 1-2.44-3.147h.001zM6.337 8.353v.772H4.244a11.9 11.9 0 0 1 2.439-3.147c-.23.77-.347 1.57-.347 2.375h.001zm6.924 17.974a5.507 5.507 0 0 1-4.072-5.309v-.772h4.072v6.081zm0-8.933H9.189v-5.418h4.072v5.418zm0-8.27H9.189v-.772a5.507 5.507 0 0 1 4.072-5.309v6.081zm11.868 0h-2.093v-.772c0-.804-.117-1.604-.346-2.375.984.905 1.808 1.97 2.438 3.148l.001-.001zm-4.944 11.893a5.507 5.507 0 0 1-4.072 5.309v-6.081h4.072v.772zm0-3.623h-4.072v-5.418h4.072v5.418zm0-8.27h-4.072V3.043a5.507 5.507 0 0 1 4.072 5.309v.772zm2.505 14.268c.23-.77.346-1.57.346-2.375v-.772h2.093a11.9 11.9 0 0 1-2.44 3.148l.001-.001zm.346-6v-5.418H26.2a11.751 11.751 0 0 1 0 5.418h-3.164z"
                          id="Path_15"
                        />
                      </g>
                    </g>
                    <g id="Group_108" transform="translate(35.074 18.106)">
                      <g id="Group_107">
                        <path
                          d="M1.426 0C.638 0 0 .638 0 1.426V5.99a1.426 1.426 0 0 0 2.852 0V1.426C2.852.638 2.214 0 1.426 0z"
                          id="Path_16"
                        />
                      </g>
                    </g>
                    <g id="Group_110" transform="translate(19.118 17.097)">
                      <g id="Group_109">
                        <path
                          d="M2.434.418A1.425 1.425 0 1 0 .42 2.435 1.425 1.425 0 0 0 2.434.418z"
                          id="Path_17"
                        />
                      </g>
                    </g>
                    <g id="Group_112" transform="translate(22.577 20.554)">
                      <g id="Group_111">
                        <path
                          d="M6.248 4.231L2.434.417A1.426 1.426 0 0 0 .418 2.433l3.814 3.814a1.426 1.426 0 1 0 2.016-2.016z"
                          id="Path_18"
                        />
                      </g>
                    </g>
                    <g id="Group_114" transform="translate(42.568 18.949)">
                      <g id="Group_113">
                        <path
                          d="M9.042.417a1.426 1.426 0 0 0-2.016 0L1.418 6.025a1.426 1.426 0 1 0 2.016 2.016l5.608-5.608a1.426 1.426 0 0 0 0-2.016z"
                          id="Path_19"
                        />
                      </g>
                    </g>
                    <g id="Group_116" transform="translate(33.648)">
                      <g id="Group_115">
                        <path
                          d="M6.175 2.852h.5a1.426 1.426 0 0 0 0-2.852h-.5a4.754 4.754 0 0 0-4.749 4.749v.384a1.426 1.426 0 0 0 0 2.852v5.678a1.426 1.426 0 1 0 2.852 0V7.984h1.936a1.426 1.426 0 0 0 0-2.852H4.278v-.383a1.9 1.9 0 0 1 1.897-1.897z"
                          id="Path_20"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_118"
                      transform="translate(52.653 1.154)"
                      fillRule="nonzero"
                    >
                      <g id="Group_117">
                        <path
                          d="M16.978 2.789a1.426 1.426 0 0 0-1.194-.647h-1.191a4.5 4.5 0 0 0-7.111 2.273A10.277 10.277 0 0 1 3.725 1.52a1.426 1.426 0 0 0-2.335.18 5.567 5.567 0 0 0-.4 4.746c-.245.264-.381.61-.381.97v.05a6.676 6.676 0 0 0 1.9 4.528 5.084 5.084 0 0 1-.926-.02 1.426 1.426 0 0 0-.926 2.617 10.144 10.144 0 0 0 5.484 1.606 9.914 9.914 0 0 0 7.566-3.328 10.489 10.489 0 0 0 2.609-6.837v-.114l.78-1.772a1.426 1.426 0 0 0-.118-1.357zm-3.407 2.267a1.427 1.427 0 0 0-.12.608l.009.381a7.282 7.282 0 0 1-6.711 7.28l.106-.082a1.426 1.426 0 0 0-.878-2.549A2.437 2.437 0 0 1 4.043 9.35a1.426 1.426 0 0 0 .644-2.605 2.688 2.688 0 0 1-1.045-1.338 13.061 13.061 0 0 0 4.807 2.356 1.436 1.436 0 0 0 1.75-1.7 1.648 1.648 0 0 1 2.804-1.518c.17.182.385.316.623.389l-.055.122z"
                          id="Path_21"
                        />
                      </g>
                    </g>
                    <g id="Group_120" transform="translate(5.494 4.278)">
                      <g id="Group_119">
                        <path
                          d="M11.603 5.088H8.041a1.426 1.426 0 0 0 0 2.852h1.847a3.663 3.663 0 1 1-3.374-5.088 1.426 1.426 0 0 0 0-2.852 6.514 6.514 0 1 0 6.514 6.514c0-.787-.638-1.425-1.425-1.426z"
                          id="Path_22"
                        />
                      </g>
                    </g>
                    <g id="Group_122" transform="translate(17.635 1.154)">
                      <g id="Group_121">
                        <path
                          d="M6.468 3.021h-.6v-.6a1.426 1.426 0 0 0-2.852 0v.6h-.6a1.426 1.426 0 0 0 0 2.852h.6v.6a1.426 1.426 0 0 0 2.852 0v-.6h.6a1.426 1.426 0 0 0 0-2.852z"
                          id="Path_23"
                        />
                      </g>
                    </g>
                    <g id="Group_124" transform="translate(53.821 43.147)">
                      <g id="Group_123">
                        <path
                          d="M7.213 0H2.425a1.426 1.426 0 0 0 0 2.852h4.788a1.426 1.426 0 0 0 0-2.852z"
                          id="Path_24"
                        />
                      </g>
                    </g>
                    <g id="Group_126" transform="translate(10.883 43.147)">
                      <g id="Group_125">
                        <path
                          d="M6.213 0H1.426a1.426 1.426 0 0 0 0 2.852h4.788a1.426 1.426 0 0 0 0-2.852h-.001z"
                          id="Path_25"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
    )
}