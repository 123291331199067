import React from 'react'

export const WhatYouGetIcon = () => {
    return (
        <svg width="73" height="73" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <g
                    id="target"
                    transform="translate(-1 -1)"
                    fill="currentColor"
                  >
                    <g
                      id="Group_128"
                      transform="translate(0 1)"
                      fillRule="nonzero"
                    >
                      <g id="Group_127">
                        <path
                          d="M44.306 0c-15.089.019-27.76 11.357-29.451 26.351l-2.14-2.14a1.425 1.425 0 0 0-1.007-.417H2.425a1.424 1.424 0 0 0-1.007 2.431l3.409 3.409-3.41 3.408a1.424 1.424 0 0 0 1.008 2.431h9.283c.378 0 .74-.15 1.007-.417l2.14-2.14a29.543 29.543 0 0 0 4.021 11.94 1.424 1.424 0 0 0 2.443-1.465 26.719 26.719 0 0 1-3.756-12.333H22.7a21.526 21.526 0 0 0 3.648 10.685 1.425 1.425 0 1 0 2.361-1.595 18.686 18.686 0 0 1-3.158-9.091H30.7a13.716 13.716 0 0 0 9.173 11.516l-24.952 6.429H9.129a3.837 3.837 0 0 0-3.774 3.171 5.721 5.721 0 0 0 .016 11.12A3.843 3.843 0 0 0 8.1 66.239l2.278 5.79c.215.544.741.9 1.326.9h5.976a1.425 1.425 0 0 0 1.326-1.946l-1.554-3.948 22.5 5.848a1.424 1.424 0 0 0 1.783-1.379v-27.57c0-.344-.125-.676-.352-.935A13.681 13.681 0 1 0 30.7 28.21h-5.149a18.809 18.809 0 1 1 32.055 14.724 1.424 1.424 0 0 0 2.014 2.015A21.657 21.657 0 1 0 22.7 28.21h-5.141c.773-14.494 12.95-25.735 27.46-25.35 14.509.387 26.07 12.26 26.072 26.774a26.525 26.525 0 0 1-5.606 16.4 1.424 1.424 0 1 0 2.251 1.746 29.349 29.349 0 0 0 6.2-18.146C73.918 13.277 60.663.02 44.306 0zM11.117 32.625H5.863l1.984-1.984a1.425 1.425 0 0 0 0-2.015l-1.984-1.983h5.254l2.991 2.991-2.991 2.991zM5.3 60.225a2.867 2.867 0 0 1 0-4.984v4.984zm3.831 3.306a.983.983 0 0 1-.982-.982v-9.716c0-.542.44-.981.982-.982h4.548v11.68h-4.55.002zm3.548 6.552l-1.457-3.7h2.911l1.457 3.7h-2.913.002zm26.212-.418l-22.365-5.811V51.53l16.1-4.145V58.45a1.425 1.425 0 0 0 2.849 0v-11.8l3.417-.88v23.895h-.001zm5.417-41.456h-2.473a2.855 2.855 0 1 1 0 2.849h2.471a1.425 1.425 0 0 0 0-2.849h.002zm0 7.128a5.7 5.7 0 1 0-5.524-7.128h-5.213a10.832 10.832 0 1 1 0 2.849h5.211a5.714 5.714 0 0 0 5.524 4.28l.002-.001z"
                          id="Path_26"
                        />
                      </g>
                    </g>
                    <g id="Group_130" transform="translate(61.068 49.883)">
                      <g id="Group_129">
                        <path
                          d="M3.475.453A1.427 1.427 0 0 0 1.464.378l-.026.024a1.424 1.424 0 1 0 1.961 2.066l.009-.008A1.422 1.422 0 0 0 3.475.453z"
                          id="Path_27"
                        />
                      </g>
                    </g>
                    <g id="Group_132" transform="translate(53.183 46.97)">
                      <g id="Group_131">
                        <path
                          d="M2.432.417A1.424 1.424 0 1 0 .418 2.431c5.136 5.152 5.136 13.487 0 18.639a1.424 1.424 0 1 0 2.014 2.014c6.249-6.264 6.249-16.403 0-22.667z"
                          id="Path_28"
                        />
                      </g>
                    </g>
                    <g id="Group_134" transform="translate(49.553 50.599)">
                      <g id="Group_133">
                        <path
                          d="M2.432.417A1.424 1.424 0 1 0 .417 2.431a8.056 8.056 0 0 1 0 11.38 1.424 1.424 0 1 0 2.014 2.015c4.249-4.258 4.25-11.15.001-15.409z"
                          id="Path_29"
                        />
                      </g>
                    </g>
                    <g id="Group_136" transform="translate(45.726 54.426)">
                      <g id="Group_135">
                        <path
                          d="M2.432.417A1.425 1.425 0 0 0 .417 2.432a2.638 2.638 0 0 1 0 3.726 1.424 1.424 0 0 0 2.014 2.014A5.49 5.49 0 0 0 2.432.417z"
                          id="Path_30"
                        />
                      </g>
                    </g>
                    <g id="Group_138" transform="translate(32.623 63.391)">
                      <g id="Group_137">
                        <path
                          d="M2.432.419A1.423 1.423 0 1 0 .42 2.432 1.423 1.423 0 0 0 2.432.419z"
                          id="Path_31"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
    )
}