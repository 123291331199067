import React from 'react'
import DescriptionBox from './DescriptionBox/DescriptionBox'

function ServiceDescription({ boxes }) {
  const boxesList = boxes ? boxes.map(box => (
    <DescriptionBox
      key={box.id}
      icon={box.icon}
      heading={box.heading}
      paragraph1={box.paragraph1}
      paragraph2={box.paragraph2}
      additionalClass={box.additionalClass}
      smallHeading={box.smallHeading}
    />
  )) : null;

  return (
    <section className="service-description">
      <div className="container">
        <div className="service-description__inner">
          {boxesList}
        </div>
      </div>
    </section>
  )
}

export default ServiceDescription
