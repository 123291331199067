import React from 'react'

export const ChangesIcon = () => {
    return (
        <svg width="71" height="71" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <g
                    id="digital-marketing"
                    transform="translate(0 -1)"
                    fill="currentColor"
                  >
                    <g id="Group_78" transform="translate(16.086 5.438)">
                      <g id="Group_77">
                        <path
                          d="M11.371 0H1.387a1.387 1.387 0 0 0 0 2.773h9.984a1.387 1.387 0 0 0 0-2.773z"
                          id="Path_1"
                        />
                      </g>
                    </g>
                    <g id="Group_80" transform="translate(21.078 63.924)">
                      <g id="Group_79">
                        <path
                          d="M2.367.406a1.386 1.386 0 1 0-1.96 1.96 1.386 1.386 0 0 0 1.96-1.96z"
                          id="Path_2"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_82"
                      transform="translate(0 1)"
                      fillRule="nonzero"
                    >
                      <g id="Group_81">
                        <path
                          d="M62.085 44.08l-5.1-8.837a6.25 6.25 0 0 0-6.094-10.555l-5.1-8.835a1.387 1.387 0 0 0-2.293-.162L27.614 35.973l-6.665 3.848a7.965 7.965 0 1 0 7.965 13.795l.794-.458 2.767 4.796H2.773v-46.3h39.383v.416a1.387 1.387 0 1 0 2.773 0V5.444A5.451 5.451 0 0 0 39.485 0H5.444A5.451 5.451 0 0 0 0 5.444v60.11a5.451 5.451 0 0 0 5.444 5.444h34.041a5.451 5.451 0 0 0 5.445-5.444V48.442l16.15-2.3a1.387 1.387 0 0 0 1.006-2.066l-.001.004zM2.773 5.444a2.674 2.674 0 0 1 2.671-2.67h34.041a2.674 2.674 0 0 1 2.671 2.67v3.431H2.773V5.444zm50.863 21.858a3.476 3.476 0 0 1 1.895 5.425l-3.188-5.522c.433-.05.872-.016 1.293.097zm-26.11 23.912a5.191 5.191 0 0 1-5.191-8.991l5.7-3.289 5.191 8.992-5.7 3.288zm4.582.557l2.5-1.444 3.276 5.674-2.5 1.444-3.276-5.674zm10.048 13.783a2.674 2.674 0 0 1-2.671 2.671H5.444a2.674 2.674 0 0 1-2.67-2.671v-4.828h39.382v4.828zm0-7.6h-2.1l.421-.243c.663-.383.89-1.23.507-1.894l-3.635-6.3 4.81-.684-.003 9.121zm-6.3-11.022l-5.647-9.778 10.086-12.88 11.75 20.352-16.189 2.306zM55 44.209L42.138 21.923l2.275-2.905 14.244 24.671-3.657.52z"
                          id="Path_3"
                        />
                      </g>
                    </g>
                    <g id="Group_84" transform="translate(60.44 20.569)">
                      <g id="Group_83">
                        <path
                          d="M10.387.694A1.387 1.387 0 0 0 8.493.187l-6.8 3.926a1.387 1.387 0 1 0 1.387 2.4l6.8-3.926c.663-.383.89-1.23.507-1.893z"
                          id="Path_4"
                        />
                      </g>
                    </g>
                    <g id="Group_86" transform="translate(54.538 14.127)">
                      <g id="Group_85">
                        <path
                          d="M3.737 1.047a1.387 1.387 0 0 0-1.7.981l-.991 3.7a1.387 1.387 0 1 0 2.679.718l.991-3.7c.199-.74-.24-1.5-.979-1.699z"
                          id="Path_5"
                        />
                      </g>
                    </g>
                    <g id="Group_88" transform="translate(63.297 32.243)">
                      <g id="Group_87">
                        <path
                          d="M5.569 1.072L1.746.047a1.387 1.387 0 0 0-.718 2.679l3.823 1.025a1.387 1.387 0 0 0 .718-2.679z"
                          id="Path_6"
                        />
                      </g>
                    </g>
                    <g id="Group_90" transform="translate(5.78 15.693)">
                      <g id="Group_89">
                        <path
                          d="M20.522 1H1.387a1.387 1.387 0 0 0 0 2.773h19.135a1.387 1.387 0 1 0 0-2.773z"
                          id="Path_7"
                        />
                      </g>
                    </g>
                    <g id="Group_92" transform="translate(23.915 23.35)">
                      <g id="Group_91">
                        <path
                          d="M3.363.406a1.386 1.386 0 1 0-1.96 1.96 1.386 1.386 0 0 0 1.96-1.96z"
                          id="Path_8"
                        />
                      </g>
                    </g>
                    <g id="Group_94" transform="translate(5.78 22.349)">
                      <g id="Group_93">
                        <path
                          d="M14.79 1H1.39a1.387 1.387 0 0 0 0 2.773h13.4a1.387 1.387 0 1 0 0-2.773z"
                          id="Path_9"
                        />
                      </g>
                    </g>
                    <g id="Group_96" transform="translate(10.106 29.006)">
                      <g id="Group_95">
                        <path
                          d="M12.901 1H2.387a1.387 1.387 0 1 0 0 2.773H12.9A1.387 1.387 0 0 0 12.9 1h.001z"
                          id="Path_10"
                        />
                      </g>
                    </g>
                    <g id="Group_98" transform="translate(5.768 29.006)">
                      <g id="Group_97">
                        <path
                          d="M1.451 1h-.064a1.387 1.387 0 0 0 0 2.773h.064a1.387 1.387 0 1 0 0-2.773z"
                          id="Path_11"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
    )
}